<script lang="ts">
import MsLoginBtn from '@assets/ms-login-btn-dark.svg';
import { Alert, Button, Input, Label, Spinner } from 'flowbite-svelte';
import { Register, Section } from 'flowbite-svelte-blocks';
import { EyeOutline, EyeSlashOutline } from 'flowbite-svelte-icons';

export let errorMessage: string | null;
export let message: string | null;
let showPass = false;
let loading = false;

function loadSymbol() {
	loading = true;
}

if (window.location.hash) {
	let hash = window.location.hash;
	if (hash.includes('error')) {
		hash = hash.split('error_description=')[1];
		hash = hash.split('&')[0];
		hash = decodeURIComponent(hash);
		hash = hash.replaceAll('+', ' ');
	}
	if (message) {
		message = `${message}\n${hash.replace('#', '')}`;
	} else {
		message = hash.replace('#', '');
	}
}

$: if (errorMessage) {
	loading = false;
}
</script>

<div class="m-auto max-w-lg bg-slate-100 shadow-xl">
    <Section name="login">
        <Register divClass="bg-slate-100">
            <svelte:fragment slot="top">
                <img
                    class="mr-5 mt-5 h-12"
                    src="/fd-logo-grey.png"
                    alt="logo" />
                deck.aero
            </svelte:fragment>
            <div class="space-y-4 p-6 sm:p-8 md:space-y-6">
                {#if message != null}
                    <Alert>{message}</Alert>
                {/if}
                <form
                    class="flex flex-col space-y-6"
                    method="post"
                    on:submit={loadSymbol}>
                    <Label class="space-y-2">
                        <span>Your email</span>
                        <Input
                            name="email"
                            id="email"
                            type="email"
                            placeholder="pilot@flybyguys.com" />
                    </Label>
                    <Label class="space-y-2">
                        <span>Your password</span>
                        <Input
                            type={showPass ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="•••••">
                            <button
                                type="button"
                                slot="right"
                                on:click={() => (showPass = !showPass)}
                                class="pointer-events-auto">
                                {#if showPass}
                                    <EyeOutline />
                                {:else}
                                    <EyeSlashOutline />
                                {/if}
                            </button>
                        </Input>
                    </Label>
                    <Button type="submit" class="rounded bg-fbgred p-2">
                        {#if loading === false}
                            Sign in
                        {:else}
                            <Spinner class="me-3" size="5" color="white" />
                            Loading ...
                        {/if}
                    </Button>
                    <button
                        value="azure"
                        name="provider"
                        type="submit"
                        class="flex justify-center p-2">
                        <img src={MsLoginBtn.src} alt="Login with Microsoft" />
                    </button>
                    <a
                        href="/forgotPassword"
                        class="text-md mx-auto font-light text-fbggrey underline hover:text-fbgred"
                        >Forgot Password?</a>
                    {#if errorMessage != null}
                        <p class="text-red-500">{errorMessage}</p>
                    {/if}
                </form>
            </div>
        </Register>
    </Section>
</div>
